import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import { createTagsList } from '../utils/tag-creator'

const SEO = ({ data }) => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(
          fileAbsolutePath: {regex: "/content/additional-content/general-seo/general-seo.*/"}
        ) {
          frontmatter {
            copyright
            ogtype
            siteName
            locale
          }
        }
      }
    `)

  const mergedObject = { ...markdownRemark.frontmatter, ...data }
  const tags = createTagsList(mergedObject)
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={data && data.title ? data.title : ''}
      meta={tags.meta}
      link={tags.link}
    />
  )
}

export default SEO
