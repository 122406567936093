// config that describe tags structure
const config = {
  locale: {
    tag: 'meta',
    attribute1: {
      name: 'property',
      value: 'og:locale'
    },
    attribute2: {
      name: 'content'
    }
  },
  copyright: {
    tag: 'meta',
    attribute1: {
      name: 'name',
      value: 'copyright'
    },
    attribute2: {
      name: 'content'
    }
  },
  ogtype: {
    tag: 'meta',
    attribute1: {
      name: 'property',
      value: 'og:type'
    },
    attribute2: {
      name: 'content'
    }
  },
  siteName: [
    {
      tag: 'meta',
      attribute1: {
        name: 'name',
        value: 'og:site_name'
      },
      attribute2: {
        name: 'content'
      }
    },
    {
      tag: 'meta',
      attribute1: {
        name: 'name',
        value: 'twitter:site'
      },
      attribute2: {
        name: 'content'
      }
    }
  ],
  canonical: {
    tag: 'link',
    attribute1: {
      name: 'rel',
      value: 'canonical'
    },
    attribute2: {
      name: 'href'
    }
  },
  ogurl: {
    tag: 'meta',
    attribute1: {
      name: 'property',
      value: 'og:url'
    },
    attribute2: {
      name: 'content'
    }
  },
  title: [
    {
      tag: 'meta',
      attribute1: {
        name: 'property',
        value: 'og:title'
      },
      attribute2: {
        name: 'content'
      }
    },
    {
      tag: 'meta',
      attribute1: {
        name: 'name',
        value: 'twitter:title'
      },
      attribute2: {
        name: 'content'
      }
    }
  ],
  description: [
    {
      tag: 'meta',
      attribute1: {
        name: 'name',
        value: 'description'
      },
      attribute2: {
        name: 'content'
      }
    },
    {
      tag: 'meta',
      attribute1: {
        name: 'property',
        value: 'og:description'
      },
      attribute2: {
        name: 'content'
      }
    },
    {
      tag: 'meta',
      attribute1: {
        name: 'name',
        value: 'twitter:description'
      },
      attribute2: {
        name: 'content'
      }
    }
  ],
  keywords: {
    tag: 'meta',
    attribute1: {
      name: 'name',
      value: 'keywords'
    },
    attribute2: {
      name: 'content'
    }
  },
  robots: {
    tag: 'meta',
    attribute1: {
      name: 'name',
      value: 'robots'
    },
    attribute2: {
      name: 'content'
    }
  },
  image: [
    {
      tag: 'meta',
      attribute1: {
        name: 'property',
        value: 'og:image'
      },
      attribute2: {
        name: 'content'
      }
    },
    {
      tag: 'meta',
      attribute1: {
        name: 'name',
        value: 'twitter:image'
      },
      attribute2: {
        name: 'content'
      }
    }
  ],
  imageAlt: {
    tag: 'meta',
    attribute1: {
      name: 'property',
      value: 'og:image:alt'
    },
    attribute2: {
      name: 'content'
    }
  },
  twitterCard: {
    tag: 'meta',
    attribute1: {
      name: 'name',
      value: 'twitter:card'
    },
    attribute2: {
      name: 'content'
    }
  }
}

// function to create single tag
const createTag = (data, key) => {
  const params = config[key]
  let value = ''

  // keywords array need to be modified to string
  if (key === 'keywords') {
    if (!!data && data[0] !== '') {
      value = data.join(`, `)
    } else {
      return {}
    }
  } else {
    value = data
  }

  return {
    [`${ params.attribute1.name }`]: params.attribute1.value,
    [`${ params.attribute2.name }`]: value
  }
}

/* function to create multiple tags from one value
  for some tags value is the same,
  so we can create multi tags from one value
*/
const createTagFromArray = (data, key) => {
  const result = []

  for (let obj of config[key]) {
    const value = data

    // for image url we need add domain
    if (key === 'image') {
      result.push({
        [`${ obj.attribute1.name }`]: obj.attribute1.value,
        [`${ obj.attribute2.name }`]:
          `${ process.env.GATSBY_SITE_URL }${ value.childImageSharp.original.src }`
      })
    } else {
      result.push({
        [`${ obj.attribute1.name }`]: obj.attribute1.value,
        [`${ obj.attribute2.name }`]: value
      })
    }
  }
  return result
}

// function which create two arrays with tags objects (meta, link)
export const createTagsList = (data = {}) => {
  // retrun empty object if data is empty
  if (!Object.keys(data || {}).length) {
    return {}
  }

  const link = []
  const meta = []

  for (let key in data) {
    if (data.hasOwnProperty(key) && data[key]) {
      switch (key) {
      case 'locale':
      case 'copyright':
      case 'ogtype':
      case 'robots':
      case 'imageAlt':
      case 'twitterCard':
        meta.push(createTag(data[key], key))
        break
      case 'keywords':
        meta.push(createTag(data[key], key))
        break
      case 'siteName':
      case 'title':
      case 'description':
      case 'image':
        meta.push(...createTagFromArray(data[key], key))
        break
      case 'canonical':
        link.push(createTag(data[key], 'canonical'))
        meta.push(createTag(data[key], 'ogurl'))
        break
      }
    }
  }
  return { link, meta }
}
